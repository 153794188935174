'use client'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { addTracking, TrackingProps } from '../actions/addTrk'
import { useSession } from '@repo/users'
import { usePathname } from 'next/navigation'
import { TrackEvent } from '../types/TrackEvents'
import { useTrackingStore } from '../stores/tracking-store'
import { getTimeString } from '../actions/getTime'

export type TrackingContextProps = {
  addTrack: ({}: TrackingProps) => void
  isLoading: boolean
}
const TrackingContext = createContext<TrackingContextProps>({
  addTrack: ({}: TrackingProps) => {},
  isLoading: false,
})

export const useTracking = () => useContext(TrackingContext)

export function TrackingProvider({ children }: { children: React.ReactNode }) {
  const { session, isLoading: isSessionLoading } = useSession()
  const [isLoading, setIsLoading] = useState(false)
  const pathname = usePathname()
  const { addEvent, events, removeEvents } = useTrackingStore()

  const processQueue = useCallback(
    async (userId: number, platformId: number, opoId: number) => {
      if (!userId || events.length === 0 || isLoading) return

      setIsLoading(true)
      try {
        await addTracking({ userId, events: events, platformId, opoId })

        removeEvents(events)
      } catch (error) {
        console.error('Error processing tracking queue', error)
      } finally {
        setIsLoading(false)
      }
    },
    [session, events, isLoading]
  )

  useEffect(() => {
    if (
      session?.user?.id &&
      session.user.platformId &&
      session.user.opoId &&
      !isSessionLoading
    ) {
      processQueue(session.user.id, session.user.platformId, session.user.opoId)
    }
  }, [session, isSessionLoading, processQueue])

  useEffect(() => {
    const add = async () => {
      const url = `${pathname}`

      await addTrack({
        event: TrackEvent.PAGE_VIEW,
        value: url,
      })
    }

    add()
  }, [pathname]) // Dependencias del efecto

  const addTrack = async (event: TrackingProps) => {
    const dateTime = await getTimeString()

    addEvent({ ...event, createdAt: dateTime })
  }

  return (
    <TrackingContext.Provider
      value={{
        addTrack,
        isLoading,
      }}>
      {children}
    </TrackingContext.Provider>
  )
}
